<template>
  <b-card
    no-body
    class="card-transaction"
  >
    <b-card-header>
      <div>
        <b-card-title>Arsitektur {{ title }}</b-card-title>
        <b-card-text class="font-small-2">
          Jumlah Permohonan Pada Domain
        </b-card-text>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="(data, index) in listData"
        :key="index"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-body>
            <h6 class="transaction-title">
              {{ data.nama }}
            </h6>
            <span>{{ data.total }} {{ (title === 'Aplikasi' || title === 'Infrastruktur') ? title : 'Kegiatan' }}</span>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder text-success">
          {{ Number(data.anggaran).toLocaleString() }}
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaBody,
  },
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      browserData: [
        {
          name: 'Google Chrome',
          usage: '54.4%',
        },
        {
          name: 'Mozila Firefox',
          usage: '6.1%',
        },
        {
          name: 'Apple Safari',
          usage: '14.6%',
        },
        {
          name: 'Internet Explorer',
          usage: '4.2%',
        },
        {
          name: 'Opera Mini',
          usage: '8.%',
        },
      ],
    }
  },
}
</script>
