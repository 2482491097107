<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <h3 class="p-1">
      Top 10 Instansi
    </h3>
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- nama instansi -->
      <template #cell(name)="data">
        <div class="font-weight-bolder">
          <b-link
            :to="{ name: 'pengajuan-instansi-detail', params: { id: data.item.instansi_id } }"
            class="text-primary"
          >
            {{ data.item.name }}
          </b-link>
        </div>
      </template>

      <!-- total anggaran -->
      <template #cell(total_anggaran)="data">
        <div class="text-right">
          Rp. {{ Number(data.item.total_anggaran).toLocaleString() }}
        </div>
      </template>

      <!-- disetujui -->
      <template #cell(approve)="data">
        <div class="text-right">
          Rp. {{ Number(data.item.approve).toLocaleString() }}
        </div>
      </template>

      <!-- ditunda -->
      <template #cell(reject)="data">
        <div class="text-right">
          Rp. {{ Number(data.item.reject).toLocaleString() }}
        </div>
      </template>

      <!-- verifikasi -->
      <template #cell(pending_procurement)="data">
        <div class="text-right">
          {{ Number(data.item.pending_procurement).toLocaleString() }}
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col
        class="mx-2 py-1"
        cols="12"
      >
        <b-link
          href="/instansi"
          class="btn btn-primary btn-sm"
        >
          Selengkapnya
        </b-link>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BTable, BLink, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Instansi' },
        { key: 'total_anggaran', label: 'Total Anggaran' },
        { key: 'approve', label: 'Anggaran Disetujui' },
        { key: 'reject', label: 'Anggaran Ditunda' },
        { key: 'pending_procurement', label: 'Menunggu Verifikasi' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
