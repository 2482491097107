<template>
  <b-card
    v-if="show"
    class="earnings-card"
  >
    <b-row>
      <b-col cols="12">
        <b-card-title class="mb-1">
          {{ title }}
        </b-card-title>
        <!-- chart -->
        <vue-apex-charts
          height="150"
          :options="earningsChart.chartOptions"
          :series="seriesData"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = '#60d1d866'
const $earningsStrokeColor3 = '#60d1d833'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      seriesData: [],
      seriesLabels: [],
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: [],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
    if (this.data) {
      this.seriesData = []
      this.seriesLabels = []

      this.data.map(values => {
        if (values.nama !== '') {
          this.seriesData.push(values.total)
          this.seriesLabels.push(values.nama)
        }
        return true
      })

      this.earningsChart.chartOptions.labels = this.seriesLabels
      this.show = true
    }
  },
}
</script>
