<template>
  <b-card
    body-class="pb-50"
  >
    <h6>{{ title }}</h6>
    <h2 class="font-weight-bolder mb-1 text-right">
      {{ app }} {{ Number(totalData).toLocaleString() }}
    </h2>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    totalData: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    app: {
      type: String,
      default: '',
    },
  },
}
</script>
